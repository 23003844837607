import React from "react";

import { useWindowSize } from "../../hooks";
import DesktopMenu from "./DesktopMenu/DesktopMenu";
import styles from "./menu.module.css";
import MobileMenu from "./MobileMenu/MobileMenu";

const Menu = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize?.width < 1000;
  const items = [
    { href: "/uppdrag/skapa", label: "Starta nytt städuppdrag" },
    {
      href: "/uppdrag",
      isActive: (match, location) =>
        (match?.url === "/uppdrag" || location?.pathname === "/") &&
        location?.pathname !== "/uppdrag/skapa",
      label: "Alla städuppdrag",
    },
    { href: "/om", label: "Om CleanApp" },
  ];
  const slogan = "Städa naturen och tjäna extrapengar!";
  const MenuComponent = isMobile ? MobileMenu : DesktopMenu;
  return (
    <div className={styles.container}>
      <MenuComponent items={items} slogan={slogan} />
    </div>
  );
};

export default Menu;
