import React from "react";

const Background = () => {
  return (
    <div>
      <b>Socialt entreprenörskap inom den cirkulära ekonomin</b>
      <p>
        Grunden för socialt entreprenörskap inom den cirkulära ekonomin är att
        finna affärsmässigt hållbara lösningar på olika former av
        samhällsproblem och då med en långsiktig ekonomisk, ekologisk och social
        hållbarhet genom hela utvecklings- och affärsprocessen.
      </p>
      <p>
        Allt för länge togs det för givet att enbart frivilliginsatser och den
        goda viljan kunde, och borde, lösa flera av vår gemensamma utmaningar
        och samhällsproblem. Att företagande och affärer inte kunde, och inte
        borde, vara en del av dessa lösningar. Detta sågs länge som en
        vedertagen sanning, att det inte gick att skapa ekonomiskt långsiktigt
        hållbara modeller för dessa utmaningar.
      </p>
      <p>
        Idag ser man annorlunda på detta och numera är det en självklarhet att
        näringslivet, dess resurser och dess innovationskraft är en ovärderlig
        del av att ta fram nödvändiga och gemensamma lösningar på de utmaningar
        vi alla står inför, både ekonomiskt, ekologiskt och socialt.
      </p>
      <b>Stiftelsen För Gemensamt Engagemang</b>
      <p>
        2006 grundades Stiftelsen för Gemensamt Engagemang med ändamål att bl.a.
        främja goda miljöer och verksamhet som leder till ökad självkänsla samt
        ökat självförtroende, hos ungdomar, långtidsarbetslösa och utstötta i
        samhället.
      </p>
      <p>
        Stiftelsen för Gemensamt Engagemangs ändamålsuppfyllnad och verksamhet
        granskas årligen av Länsstyrelsen Västra Götaland och KPMG är
        stiftelsens oberoende och auktoriserade revisor vilka årligen granskar
        dess räkenskaper.
      </p>
      <p>
        Läs gärna mer om Stiftelsen för Gemensamt Engagemang här:{" "}
        <a href="//www.stiftelsenge.se" rel="noreferrer" target="_blank">
          www.stiftelsenge.se
        </a>
      </p>
      <b>Vår första utmaning – kampen mot matsvinnet!</b>
      <img
        alt="buske"
        src={require("../../../images/illustrations/bush.png")}
        style={{ float: "right", width: 200 }}
      />
      <p>
        2010 grundades Allwin AB, som sedan starten och än idag ägs av
        Stiftelsen för Gemensamt Engagemang, för att förebygga och minska
        matsvinnet och för att hjälpa utsatta att få tillgång till näringsrik
        mat i Sverige. Detta genom att storskaligt re-distribuera matsvinn från
        livsmedelsbutiker till hjälporganisationer.
      </p>
      <p>
        Idag är Allwin den enskilt största organisationen inom området och sedan
        starten har Allwin räddat och re-distribuerat över 5 miljoner kilo mat
        till utsatta, motsvarande 1 miljon matkassar till ett marknadsvärde på
        över 250 miljoner kronor.
      </p>
      <p>
        För detta har Allwin fått otaliga utmärkelser, exempelvis vinnare av
        Samhalls Visa vägen-pris som årets arbetsgivare i Västsverige, årets
        eldsjäl vid Samhalls Visa vägen Gala, topp fem vid Nordic Council
        Environment Prize, vinnare av Svenskt Näringslivs pris Årets företagare
        i Göteborg, vinnare av det västsvenska priset Corporate Social
        Responsibility, vinnare av utmärkelsen Årets Entreprenör vid Swedish
        Recycling Award samt juryns hederspris & Årets miljöhjälte av
        Världsnaturfonden WWF.
      </p>
      <p>
        Läs gärna mer om Allwin här:{" "}
        <a href="//www.allwin.nu" rel="noreferrer" target="_blank">
          www.allwin.nu
        </a>
      </p>
      <b>Vår nästa utmaning - kampen mot nedskräpningen!</b>
      <p>
        Parallellt med Allwins viktiga arbete, fortsätta tillväxt och stora
        samhällsnytta beslutade vi i Stiftelsen för Gemensamt Engagemang våren
        2020 att, utöver matsvinnet, nu angripa ytterligare ett stort
        samhällsproblem - nedskräpningen i vår natur!
      </p>
      <p>
        Efter att ha läst på om problemets oerhörda omfattning och studerat
        mängder av olika lokala, nationella och internationella initiativ och
        alternativa problemlösningar inom området utmejslades vår modell –
        CleanApp - successivt. Här började nu vår nästa resa och vårt bidrag i
        kampen mot nedskräpningen av vår natur!
      </p>
      <b>Samarbeta med CleanApp</b>
      <img
        alt="löv"
        src={require("../../../images/illustrations/leaf.png")}
        style={{ float: "right", width: 200 }}
      />
      <p>
        I samband med att vi läste på om nedskräpningsproblematikens oerhörda
        omfattning så träffade vi flertalet eldsjälar, experter och
        organisationer vilka alla kunde lära oss mer, bidra på olika sätt och
        konstruktivt lyssna till våra tankar, idéer och visioner.
      </p>
      <b>CleanApp</b>
      <p>
        Våren 2021 beslutade statliga Almi att bidra med utvecklingsmedel i form
        av 50.000 kronor till CleanApps digitala utveckling. Ett viktigt stöd
        för att ta de första utvecklingsstegen.
      </p>
      <p>
        Under hösten 2021 grundades därefter CleanApp AB, som ägs av Stiftelsen
        för Gemensamt Engagemang, samt den till verksamheten tillhörande
        digitala plattformen och webbplatsen{" "}
        <a href="//www.cleanapp.se" rel="noreferrer" target="_blank">
          www.cleanapp.se
        </a>
      </p>
      <b>Samarbete och stöd!</b>
      <p>
        Tillsammans kan vi göra skillnad och vi söker därför hela tiden nya
        samarbeten eller stöd. Vill du, ditt företag eller din organisation
        också bidra med kunskap, resurser eller kontakter så hör av dig till oss
        så ska vi tillsammans och helt förutsättningslöst hitta ett sätt som
        även ni kan bidra med och göra nytta för miljön och våra ungdomar
        tillsammans med CleanApp!
      </p>
      <b>Kontakt</b>
      <p>
        Hör gärna av dig om du vill delta, hjälp till eller bara höra mer om hur
        vi tillsammans kan göra nytta!
      </p>
      <p>
        Mail:{" "}
        <a href="mailto:info@cleanapp.se" rel="noreferrer" target="_blank">
          info@cleanapp.se
        </a>
        <br />
        Facebook:{" "}
        <a
          href="//www.facebook.com/cleanapp.se"
          rel="noreferrer"
          target="_blank"
        >
          www.facebook.com/cleanapp.se
        </a>
      </p>
    </div>
  );
};

export default Background;
